import React from "react"
import PropTypes from "prop-types"
import ResetImage1 from "../images/hiit_it_interval_timer_iphone-reset.webp"
import ResetImage2 from "../images/hiit_it_interval_timer_iphone-reset@2x.webp"
import ResetImage3 from "../images/hiit_it_interval_timer_iphone-reset@3x.webp"
import ResetImage4 from "../images/hiit_it_interval_timer_iphone-reset@4x.webp"

import MoveImage1 from "../images/hiit_it_interval_timer_iphone-move.webp"
import MoveImage2 from "../images/hiit_it_interval_timer_iphone-move@2x.webp"
import MoveImage3 from "../images/hiit_it_interval_timer_iphone-move@3x.webp"
import MoveImage4 from "../images/hiit_it_interval_timer_iphone-move@4x.webp"

import CompleteImage1 from "../images/hiit_it_interval_timer_iphone-complete.webp"
import CompleteImage2 from "../images/hiit_it_interval_timer_iphone-complete@2x.webp"
import CompleteImage3 from "../images/hiit_it_interval_timer_iphone-complete@3x.webp"
import CompleteImage4 from "../images/hiit_it_interval_timer_iphone-complete@4x.webp"

const types = {
  reset: `${ResetImage1} 1x, ${ResetImage2} 2x, ${ResetImage3} 3x, ${ResetImage4} 4x`,
  move: `${MoveImage1} 1x, ${MoveImage2} 2x, ${MoveImage3} 3x, ${MoveImage4} 4x`,
  complete: `${CompleteImage1} 1x, ${CompleteImage2} 2x, ${CompleteImage3} 3x, ${CompleteImage4} 4x`,
}

const ScreenshotIphone = ({ className, type }) => (
  <div className={className}>
    <img
      srcSet={types[type]}
      alt="Screenshot of Intervals Pro: Tabata & HIIT"
      width="99"
      height="200"
    />
  </div>
)

ScreenshotIphone.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
}

ScreenshotIphone.defaultProps = {
  className: ``,
  type: "move",
}

export default ScreenshotIphone
